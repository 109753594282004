/** @jsx jsx */
import { jsx } from 'theme-ui'
import { Link } from "gatsby"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"
import { RiPrinterLine, RiDownloadLine, RiArrowLeftLine, RiCheckLine, RiUserLine, RiTeamLine, RiBriefcaseLine, RiCalendarCheckLine, RiFileTextLine, RiShieldCheckLine, RiArrowRightSLine } from "react-icons/ri"

const WelcomeKitTemplatePage = () => {
  
  // Welcome kit key components
  const welcomeKitComponents = {
    companyInfo: [
      "Company history and mission statement",
      "Organizational structure and leadership team",
      "Company culture and core values document",
      "Product/service overview materials",
      "Brand guidelines and messaging overview"
    ],
    employeeResources: [
      "Employee handbook (digital or printed)",
      "Benefits enrollment information",
      "Payroll and time tracking procedures",
      "IT systems access instructions",
      "Office maps and facility information"
    ],
    firstDayEssentials: [
      "First day schedule and agenda",
      "Team contact information",
      "Required forms and documentation checklist",
      "Login credentials and system access information",
      "Company email signature template"
    ],
    welcomeItems: [
      "Personalized welcome letter from CEO/manager",
      "Company-branded merchandise items",
      "Team welcome card",
      "Office supplies starter kit",
      "Local area information (for relocating employees)"
    ]
  }
  
  // Welcome kit delivery formats
  const deliveryFormats = [
    { 
      format: "Digital Welcome Portal", 
      description: "Centralized digital hub with all materials organized in an interactive format",
      benefits: ["Accessible from anywhere", "Easy to update", "Interactive elements", "Trackable engagement"]
    },
    { 
      format: "Physical Welcome Box", 
      description: "Tangible package containing printed materials and branded welcome items",
      benefits: ["Creates memorable experience", "Includes physical branded items", "Works for non-desk employees", "Makes strong first impression"]
    },
    { 
      format: "Hybrid Approach", 
      description: "Combination of digital resources and select physical materials",
      benefits: ["Best of both worlds", "Cost-effective", "Adaptable to different needs", "Environmentally balanced"]
    }
  ]
  
  // Function to handle printing the template
  const handlePrint = () => {
    if (typeof window !== 'undefined') {
      window.print();
    }
  }
  
  return (
    <Layout>
      <SEO 
        title="New Employee Welcome Kit Template | Comprehensive Onboarding Package"
        description="Download our comprehensive new employee welcome kit template that includes orientation materials, company information, and first-day essentials for a successful onboarding experience."
        keywords={[
          "new employee welcome kit",
          "employee onboarding package",
          "first day essentials template",
          "new hire welcome materials",
          "employee orientation kit",
          "company welcome package",
          "onboarding welcome template",
          "new employee information package",
          "first day onboarding materials",
          "digital welcome kit template"
        ]}
      />
      
      {/* Hero Section */}
      <div sx={{
        background: 'linear-gradient(135deg, #f8f9fa 0%, #e9ecef 100%)',
        py: [4, 5],
        position: 'relative'
      }}>
        <div sx={{
          maxWidth: '1200px',
          mx: 'auto',
          px: 3
        }}>
          <div sx={{
            maxWidth: '800px',
            mx: 'auto'
          }}>
            <Link 
              to="/guides/employee-onboarding" 
              sx={{
                display: 'inline-flex',
                alignItems: 'center',
                color: 'primary',
                fontSize: '0.9rem',
                textDecoration: 'none',
                mb: 3,
                '&:hover': {
                  textDecoration: 'underline'
                }
              }}
            >
              <RiArrowLeftLine sx={{ mr: 1 }} /> Back to Employee Onboarding Guide
            </Link>
            
            <h1 sx={{
              fontSize: ['1.8rem', '2.2rem'],
              fontWeight: 700,
              mb: 3,
              color: 'primary'
            }}>
              New Employee Welcome Kit Template
            </h1>
            
            <p sx={{
              fontSize: '1rem',
              lineHeight: 1.5,
              mb: 4,
              color: 'text',
              opacity: 0.9
            }}>
              A comprehensive package of orientation materials, company information, and first-day essentials to 
              create a positive, engaging onboarding experience for your new hires.
            </p>
            
            <div sx={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: 3,
              mb: 4
            }}>
              <button 
                onClick={handlePrint}
                type="button"
                sx={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  gap: 2,
                  bg: 'primary',
                  color: 'white',
                  py: 2,
                  px: 3,
                  borderRadius: '6px',
                  fontSize: '0.95rem',
                  fontWeight: 600,
                  border: 'none',
                  cursor: 'pointer',
                  transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                  boxShadow: '0 4px 10px rgba(0,0,0,0.1)',
                  '&:hover': {
                    transform: 'translateY(-2px)',
                    boxShadow: '0 6px 15px rgba(0,0,0,0.15)'
                  }
                }}
              >
                <RiPrinterLine /> Print Template
              </button>
              
              <Link 
                to="/contact"
                sx={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  gap: 2,
                  bg: 'white',
                  color: 'primary',
                  py: 2,
                  px: 3,
                  borderRadius: '6px',
                  fontSize: '0.95rem',
                  fontWeight: 600,
                  textDecoration: 'none',
                  border: '2px solid',
                  borderColor: 'primary',
                  transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                  boxShadow: '0 4px 10px rgba(0,0,0,0.05)',
                  '&:hover': {
                    transform: 'translateY(-2px)',
                    boxShadow: '0 6px 15px rgba(0,0,0,0.1)'
                  }
                }}
              >
                <RiDownloadLine /> Request Customizable Template
              </Link>
            </div>
          </div>
        </div>
      </div>
      
      {/* Main Content */}
      <div sx={{
        maxWidth: '1200px',
        mx: 'auto',
        px: 3,
        py: [4, 5],
        "@media print": {
          maxWidth: "100%",
          p: 0
        }
      }}>
        <div sx={{
          maxWidth: '800px',
          mx: 'auto'
        }}>
          {/* Introduction */}
          <section sx={{ mb: 5 }}>
            <h2 sx={{ 
              fontSize: ['1.5rem', '1.8rem'],
              fontWeight: 700,
              mb: 3,
              color: 'primary',
              borderBottom: '2px solid',
              borderColor: 'muted',
              pb: 2
            }}>
              Why Create a Welcome Kit?
            </h2>
            
            <p sx={{ mb: 4, lineHeight: 1.6 }}>
              A thoughtfully designed welcome kit serves as the foundation for a successful onboarding experience. 
              It helps new employees feel valued, reduces first-day anxiety, and accelerates their integration into 
              your organization. A well-structured welcome kit can increase new hire engagement, improve retention, 
              and help employees reach productivity faster.
            </p>
            
            <div sx={{
              display: 'grid',
              gridTemplateColumns: ['1fr', '1fr 1fr'],
              gap: 4,
              mb: 4
            }}>
              {[
                {
                  title: "Create a Positive First Impression",
                  description: "Set the tone for the employee experience with a thoughtful, organized welcome",
                  icon: <RiUserLine />
                },
                {
                  title: "Reduce Onboarding Anxiety",
                  description: "Provide clarity and structure to help new hires feel prepared and confident",
                  icon: <RiShieldCheckLine />
                },
                {
                  title: "Accelerate Productivity",
                  description: "Give employees the information they need to get up to speed quickly",
                  icon: <RiBriefcaseLine />
                },
                {
                  title: "Reinforce Company Culture",
                  description: "Communicate your values and culture from day one in a tangible way",
                  icon: <RiTeamLine />
                }
              ].map((benefit, index) => (
                <div key={index} sx={{
                  p: 3,
                  borderRadius: '8px',
                  boxShadow: '0 2px 15px rgba(0,0,0,0.06)',
                  display: 'flex',
                  flexDirection: 'column'
                }}>
                  <div sx={{ 
                    display: 'flex', 
                    alignItems: 'flex-start',
                    mb: 2
                  }}>
                    <span sx={{ 
                      color: 'primary', 
                      fontSize: '1.5rem',
                      mr: 2,
                      lineHeight: 1
                    }}>
                      {benefit.icon}
                    </span>
                    <h3 sx={{ 
                      fontSize: '1.1rem',
                      fontWeight: 600,
                      m: 0,
                      lineHeight: 1.3
                    }}>
                      {benefit.title}
                    </h3>
                  </div>
                  <p sx={{ 
                    fontSize: '0.95rem',
                    m: 0,
                    lineHeight: 1.5 
                  }}>
                    {benefit.description}
                  </p>
                </div>
              ))}
            </div>
          </section>
          
          {/* Welcome Kit Components */}
          <section sx={{ mb: 5 }}>
            <h2 sx={{ 
              fontSize: ['1.5rem', '1.8rem'],
              fontWeight: 700,
              mb: 3,
              color: 'primary',
              borderBottom: '2px solid',
              borderColor: 'muted',
              pb: 2
            }}>
              Essential Welcome Kit Components
            </h2>
            
            <p sx={{ mb: 4, lineHeight: 1.6 }}>
              A comprehensive welcome kit should include the following categories of materials, each customized to 
              your organization's specific needs. The goal is to provide everything a new employee needs to feel 
              welcomed, informed, and prepared.
            </p>
            
            <div sx={{
              display: 'grid',
              gridTemplateColumns: ['1fr', null, '1fr 1fr'],
              gap: 4,
              mb: 5
            }}>
              {[
                {
                  title: "Company Information",
                  icon: <RiBriefcaseLine />,
                  items: welcomeKitComponents.companyInfo
                },
                {
                  title: "Employee Resources",
                  icon: <RiFileTextLine />,
                  items: welcomeKitComponents.employeeResources
                },
                {
                  title: "First Day Essentials",
                  icon: <RiCalendarCheckLine />,
                  items: welcomeKitComponents.firstDayEssentials
                },
                {
                  title: "Welcome Items",
                  icon: <RiCheckLine />,
                  items: welcomeKitComponents.welcomeItems
                }
              ].map((category, index) => (
                <div key={index} sx={{
                  p: 4,
                  borderRadius: '8px',
                  boxShadow: '0 2px 15px rgba(0,0,0,0.06)',
                  bg: 'white'
                }}>
                  <div sx={{
                    display: 'flex',
                    alignItems: 'center',
                    mb: 3
                  }}>
                    <span sx={{
                      color: 'primary',
                      fontSize: '1.5rem',
                      mr: 2
                    }}>
                      {category.icon}
                    </span>
                    <h3 sx={{
                      fontSize: '1.2rem',
                      fontWeight: 600,
                      m: 0,
                      color: 'text'
                    }}>
                      {category.title}
                    </h3>
                  </div>
                  
                  <ul sx={{
                    listStyle: 'none',
                    p: 0,
                    m: 0
                  }}>
                    {category.items.map((item, i) => (
                      <li key={i} sx={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        mb: 2,
                        fontSize: '0.95rem',
                        lineHeight: 1.5
                      }}>
                        <span sx={{
                          color: 'primary',
                          mr: 2,
                          mt: '2px',
                          flexShrink: 0
                        }}>
                          <RiCheckLine />
                        </span>
                        {item}
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </section>
          
          {/* Delivery Formats */}
          <section sx={{ mb: 5 }}>
            <h2 sx={{ 
              fontSize: ['1.5rem', '1.8rem'],
              fontWeight: 700,
              mb: 3,
              color: 'primary',
              borderBottom: '2px solid',
              borderColor: 'muted',
              pb: 2
            }}>
              Welcome Kit Delivery Formats
            </h2>
            
            <p sx={{ mb: 4, lineHeight: 1.6 }}>
              Welcome kits can be delivered in multiple formats, each with their own advantages. Consider which 
              format—or combination of formats—best suits your organization's culture, resources, and the needs of 
              your new employees.
            </p>
            
            <div sx={{
              display: 'grid',
              gridTemplateColumns: ['1fr', null, '1fr 1fr 1fr'],
              gap: 4,
              mb: 4
            }}>
              {deliveryFormats.map((format, index) => (
                <div key={index} sx={{
                  p: 4,
                  borderRadius: '8px',
                  boxShadow: '0 2px 15px rgba(0,0,0,0.06)',
                  bg: 'white',
                  display: 'flex',
                  flexDirection: 'column'
                }}>
                  <h3 sx={{
                    fontSize: '1.2rem',
                    fontWeight: 600,
                    mb: 2,
                    pb: 2,
                    borderBottom: '1px solid',
                    borderColor: 'muted',
                    color: 'primary'
                  }}>
                    {format.format}
                  </h3>
                  
                  <p sx={{
                    fontSize: '0.95rem',
                    mb: 3,
                    lineHeight: 1.5,
                    flexGrow: 1
                  }}>
                    {format.description}
                  </p>
                  
                  <div>
                    <h4 sx={{
                      fontSize: '1rem',
                      fontWeight: 600,
                      mb: 2
                    }}>
                      Key Benefits:
                    </h4>
                    
                    <ul sx={{
                      listStyle: 'none',
                      p: 0,
                      m: 0
                    }}>
                      {format.benefits.map((benefit, i) => (
                        <li key={i} sx={{
                          display: 'flex',
                          alignItems: 'flex-start',
                          mb: 1,
                          fontSize: '0.9rem',
                          lineHeight: 1.5
                        }}>
                          <span sx={{
                            color: 'primary',
                            mr: 2,
                            mt: '2px',
                            flexShrink: 0
                          }}>
                            <RiCheckLine />
                          </span>
                          {benefit}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              ))}
            </div>
          </section>
          
          {/* Template Preview */}
          <section sx={{ mb: 5 }}>
            <h2 sx={{ 
              fontSize: ['1.5rem', '1.8rem'],
              fontWeight: 700,
              mb: 3,
              color: 'primary',
              borderBottom: '2px solid',
              borderColor: 'muted',
              pb: 2
            }}>
              Welcome Kit Template Preview
            </h2>
            
            <div sx={{
              border: '1px solid',
              borderColor: 'muted',
              borderRadius: '8px',
              overflow: 'hidden',
              boxShadow: '0 2px 15px rgba(0,0,0,0.06)',
              mb: 4
            }}>
              <div sx={{
                p: 3,
                bg: 'primary',
                color: 'white'
              }}>
                <h3 sx={{
                  fontSize: '1.2rem',
                  fontWeight: 600,
                  m: 0
                }}>
                  New Employee Welcome Kit
                </h3>
              </div>
              
              <div sx={{
                p: 4,
                bg: 'white'
              }}>
                <div sx={{
                  mb: 4,
                  pb: 3,
                  borderBottom: '1px solid',
                  borderColor: 'muted'
                }}>
                  <h4 sx={{
                    fontSize: '1.1rem',
                    fontWeight: 600,
                    mb: 2,
                    color: 'primary'
                  }}>
                    Welcome to [Company Name]!
                  </h4>
                  
                  <p sx={{
                    fontSize: '0.95rem',
                    lineHeight: 1.6,
                    mb: 2
                  }}>
                    We're thrilled to have you join our team. This Welcome Kit contains everything you need to get started 
                    and feel at home in your new role. Please take some time to review these materials, and don't hesitate to 
                    reach out to your manager or HR contact with any questions.
                  </p>
                </div>
                
                <div sx={{
                  mb: 4
                }}>
                  <h4 sx={{
                    fontSize: '1rem',
                    fontWeight: 600,
                    mb: 2,
                    color: 'text'
                  }}>
                    Your First Day: [Date]
                  </h4>
                  
                  <div sx={{
                    bg: '#f8f9fa',
                    p: 3,
                    borderRadius: '6px'
                  }}>
                    <ul sx={{
                      listStyle: 'none',
                      p: 0,
                      m: 0
                    }}>
                      {[
                        "Arrival time: 9:00 AM",
                        "Location: [Office Address or Video Conference Link]",
                        "Dress code: [Company dress code]",
                        "Parking information: [Details if applicable]",
                        "What to bring: [ID, completed forms, etc.]"
                      ].map((item, i) => (
                        <li key={i} sx={{
                          mb: 2,
                          fontSize: '0.9rem',
                          lineHeight: 1.5,
                          display: 'flex',
                          alignItems: 'flex-start'
                        }}>
                          <span sx={{
                            color: 'primary',
                            mr: 2,
                            mt: '3px',
                            flexShrink: 0
                          }}>
                            <RiCheckLine size={14} />
                          </span>
                          {item}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                
                <div sx={{
                  fontSize: '0.8rem',
                  color: 'text',
                  opacity: 0.7,
                  textAlign: 'center',
                  mt: 4
                }}>
                  [This is a simplified preview. The full template includes detailed sections for all welcome kit components.]
                </div>
              </div>
            </div>
            
            <div sx={{
              display: 'flex',
              justifyContent: 'center',
              mt: 4
            }}>
              <Link 
                to="/contact"
                sx={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  gap: 2,
                  bg: 'primary',
                  color: 'white',
                  py: 2,
                  px: 4,
                  borderRadius: '6px',
                  fontSize: '1rem',
                  fontWeight: 600,
                  textDecoration: 'none',
                  transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                  boxShadow: '0 4px 10px rgba(0,0,0,0.1)',
                  '&:hover': {
                    transform: 'translateY(-2px)',
                    boxShadow: '0 6px 15px rgba(0,0,0,0.15)'
                  }
                }}
              >
                <RiDownloadLine /> Request Full Template Package
              </Link>
            </div>
          </section>
          
          {/* Implementation Tips */}
          <section sx={{ mb: 5 }}>
            <h2 sx={{ 
              fontSize: ['1.5rem', '1.8rem'],
              fontWeight: 700,
              mb: 3,
              color: 'primary',
              borderBottom: '2px solid',
              borderColor: 'muted',
              pb: 2
            }}>
              Implementation Tips
            </h2>
            
            <div sx={{
              bg: '#f8f9fa',
              p: 4,
              borderRadius: '8px',
              boxShadow: '0 2px 15px rgba(0,0,0,0.06)'
            }}>
              <ul sx={{
                listStyle: 'none',
                p: 0,
                m: 0
              }}>
                {[
                  "Customize the welcome kit to reflect your company's brand and culture",
                  "Consider the timing—deliver at least some elements before the first day",
                  "Get input from recent hires about what information would have been most helpful",
                  "Update welcome kit content regularly to ensure information stays current",
                  "Include personal touches like a handwritten note or team introduction video",
                  "Assign an onboarding buddy who can provide context for welcome kit materials",
                  "Create a digital version even if you primarily use physical kits for accessibility",
                  "Include an onboarding schedule for the first week to two weeks"
                ].map((tip, i) => (
                  <li key={i} sx={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    mb: 3,
                    fontSize: '0.95rem',
                    lineHeight: 1.5
                  }}>
                    <span sx={{
                      color: 'primary',
                      mr: 3,
                      mt: '3px',
                      flexShrink: 0
                    }}>
                      <RiCheckLine />
                    </span>
                    {tip}
                  </li>
                ))}
              </ul>
            </div>
          </section>
          
          {/* Related Resources */}
          <section>
            <h2 sx={{ 
              fontSize: ['1.5rem', '1.8rem'],
              fontWeight: 700,
              mb: 3,
              color: 'primary',
              borderBottom: '2px solid',
              borderColor: 'muted',
              pb: 2
            }}>
              Related Resources
            </h2>
            
            <div sx={{
              display: 'grid',
              gridTemplateColumns: ['1fr', null, 'repeat(2, 1fr)'],
              gap: 4
            }}>
              {[
                {
                  title: "Onboarding Process Checklist",
                  description: "Structured timeline of tasks for HR, managers, and new hires during the onboarding process.",
                  link: "/resources/templates/welcome-kit-template"
                },
                {
                  title: "Role-Specific Training Plan",
                  description: "Customizable template for creating structured learning paths for different roles.",
                  link: "/resources/templates/welcome-kit-template"
                },
                {
                  title: "Onboarding Experience Survey",
                  description: "Feedback form to evaluate and improve the onboarding process based on new hire experiences.",
                  link: "/resources/templates/welcome-kit-template"
                },
                {
                  title: "Employee Onboarding Guide",
                  description: "Comprehensive guide to effective employee onboarding best practices and strategies.",
                  link: "/guides/employee-onboarding"
                }
              ].map((resource, index) => (
                <Link 
                  key={index}
                  to={resource.link}
                  sx={{
                    textDecoration: 'none',
                    color: 'text',
                    p: 3,
                    borderRadius: '8px',
                    boxShadow: '0 2px 15px rgba(0,0,0,0.06)',
                    transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                    display: 'flex',
                    flexDirection: 'column',
                    bg: 'white',
                    '&:hover': {
                      transform: 'translateY(-3px)',
                      boxShadow: '0 6px 15px rgba(0,0,0,0.1)'
                    }
                  }}
                >
                  <h3 sx={{
                    fontSize: '1.1rem',
                    fontWeight: 600,
                    mb: 2,
                    color: 'primary'
                  }}>
                    {resource.title}
                  </h3>
                  
                  <p sx={{
                    fontSize: '0.95rem',
                    lineHeight: 1.5,
                    mb: 3,
                    flexGrow: 1
                  }}>
                    {resource.description}
                  </p>
                  
                  <div sx={{
                    display: 'flex',
                    alignItems: 'center',
                    fontSize: '0.9rem',
                    fontWeight: 600,
                    color: 'primary',
                    mt: 'auto'
                  }}>
                    {resource.link === "/resources/templates/welcome-kit-template" ? "Request Template" : "View Guide"} <RiArrowRightSLine sx={{ ml: 1 }} />
                  </div>
                </Link>
              ))}
            </div>
          </section>
        </div>
      </div>
    </Layout>
  )
}

export default WelcomeKitTemplatePage 